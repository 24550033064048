* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-feature-settings: "lnum" 1;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (min-width: 768px) {
  div[class^="Modalstyle__Modal"] {
    width: 556px;
  }
}

div[height]:not([height="0px"])[class^="Accordionstyle__Body"] {
  max-height: fit-content;
}
